@import "node_modules/@aecom/core/src/assets/styles/core.scss";

@page {
  margin: 0;
  size: 612pt 792pt;
}

.content {
  overflow: hidden;
  height: 100%;
}
body {
  font-family: "urw-din-semi-condensed", "Barlow Semi Condensed", sans-serif;
  background-color: #fff;
}

a {
  text-decoration: none !important;
}

#sidebarwrapper {
  overflow: auto;
  position: fixed;
  z-index: 2;
  left: 0px;
  top: 40px;
  bottom: 0;
  // width:550px;
  //height:100%;
  //margin-top:40px;
  background-color: #111111;
}

.app-left-nav .wrapper {
  width: 100%;
}

.table-container table.list thead > tr > th {
  color: #777 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.btn {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.hideFilter {
  width: 100%;
}

.hideFilter .filters-wrapper {
  display: none !important;
}

.managerBtn {
  width: 170px;
  position: relative;
}

.btn.solid:disabled,
.btn.solid.disabled {
  background-color: #ddd !important;
  color: #aaa;
}

.btn.outline.disabled .icon {
  background-color: #ddd !important;
}

.btn.outline:disabled,
.btn.outline.disabled {
  border-color: #ddd !important;
  color: #ddd !important;
}

.table-container table tbody tr > td > a.btn {
  opacity: 0;
}

.table-container table tbody tr:hover > td > a.btn {
  opacity: 1;
}

.btn.solid:disabled,
.btn.solid.disabled {
  background-color: rgb(221, 221, 221) !important;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .header-action {
    display: flex;
  }

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    text-transform: uppercase;
    color: #000;
  }
}

.cus tbody tr {
  border-top: 10px solid #fff;
  border-bottom: 10px solid #fff;
  border-right: 1px solid #eee;
  border-left: 1px solid #eee;

  td {
    padding: 10px;
    box-shadow: inset 0 1px #eee, inset 0 -1px #eee;
  }
}

.cus thead tr {
  box-shadow: inset 0 -6px #00b5e2;
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

.right-nav {
  display: flex;
  position: fixed;
  top: 40px;
  right: 0;
  width: 100%;
  height: calc(100% - 40px);
  background: rgba(0, 0, 0, 0.25);

  .nav-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 480px;
    height: 100%;
    background: #f8f8f8;
    box-shadow: 5px 0px 20px rgba(0, 0, 0, 0.2);

    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-name: slideIn;
  }

  header,
  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  header {
    height: 40px;
    background-color: #323e48;

    h1 {
      font-size: 17px;
      font-weight: 500;
      text-transform: uppercase;
      color: #fff;
      margin-left: 24px;
    }
  }

  main,
  footer {
    padding: 32px 24px;
  }

  .main-title,
  .main-subtitle {
    font-size: 24px;
    font-weight: 400;
    color: #000;
    line-height: 1.5;
    margin-bottom: 16px;
    border-bottom: 1px solid #00b5e2;
  }

  .user-information,
  .user-permission {
    margin-bottom: 56px;
  }

  .main-title span {
    color: #00b5e2;
  }

  .main-subtitle {
    font-size: 17px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .nav-list {
    list-style: none;
    padding-left: 0;

    li > div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btn {
        padding-right: 0;
      }
    }

    li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
    }

    li span {
      display: inline-block;
      color: #666;
      font-size: 17px;
      font-weight: 400;
    }

    li span + span {
      width: 306px;
      margin-left: 12px;
      color: #000;
    }
  }

  .user-status {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      font-size: 17px;
      font-weight: 500;
      color: #00b5e2;
      text-transform: uppercase;
    }
  }

  .contract-permission {
    .nav-list > li > div > aecom-dropdown {
      width: 244px;
    }

    .nav-list > li > aecom-dropdown:first-child {
      width: 276px;
    }

    .nav-list > li > div + aecom-dropdown,
    .nav-list > li > aecom-dropdown + aecom-dropdown {
      width: 148px;
    }

    .btn {
      font-weight: 200;
      color: #999;
      padding-left: 8px;
    }
  }

  footer div + div {
    display: flex;

    aecom-button:not(:first-child) {
      margin-left: 16px;
    }
  }
}

.appLogo {
  mask: url("./../assets/icons/appLogo.svg") no-repeat 50% 50%;
  background-color: #fff;
  width: 159px;
  height: 16px;
}

.btn.outline:disabled,
.btn.outline.disabled {
  border-color: #ddd !important;
  color: #ddd !important;
}

.roleBlock .btn {
  font-weight: 200;
  color: #999;
  padding-left: 8px;
  padding-right: 0;
}

.assignUser_tr_hover {
  line-height: 40px;
  overflow: auto;
  height: 40px;
  border-bottom: 1px solid #eeeeee;
}

.assignUser_tr_hover:hover {
  background: rgba(0, 181, 226, 0.1) !important;
}

.assignUser_td_Item {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color: #000000;
}

.assignUser_th {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #000000;
}
.asignUserTable .table-container {
  background-color: rgba(255, 255, 255, 0) !important;
}
.ng-tooltip {
  background-color: #000;
  color: #fff;
}

.ng-tooltip-bottom:after {
  border-color: transparent transparent #000 transparent;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.btn-tab {
  width: 100%;
  height: 40px;
  color: #000;

  &.bg-grey-e {
    color: #999;
  }
}
.matchToTab .form-group .header {
  height: 32px;
}
.form-group-header {
  padding: 4px 0;
  height: 32px;
}